<template>
	<div>
		<!-- 公共头部 -->
		<Header ></Header>
		
		<div class="section1">
			<div class="box">
				<h2 class="title">{{newsInfo.info.title}}</h2>
				<div class="info">
					<time class="time">日期：{{newsInfo.info.addtime}}</time>
					<span class="num">浏览：{{newsInfo.info.num}}</span>
				</div>
			</div>
			<div class="singlepage" v-html="newsInfo.info.content"></div>
		</div>
		<div class="section2">
			<div class="item">
				<span class="text">上一篇：</span>
				<router-link class="link" v-if="newsInfo.prev" :to="'/helpInfo?id='+newsInfo.prev.cate_id+'&aid='+newsInfo.prev.id">{{newsInfo.prev.title}}</router-link>
				<span class="link" v-else>没有啦！</span>
			</div>
			<div class="item">
				<span class="text">下一篇：</span>
				<router-link class="link" v-if="newsInfo.next" :to="'/helpInfo?id='+newsInfo.next.cate_id+'&aid='+newsInfo.next.id">{{newsInfo.next.title}}</router-link>
				<span class="link" v-else>没有啦！</span>
			</div>
		</div>
		
		<!-- 公共底部 -->
		<!-- <Footer></Footer> -->
	</div>
</template>

<script>
	import Header from '@/components/Public/Header.vue';
	import Footer from '@/components/Public/Footer.vue';
	export default {
		components: {
			Header,
			Footer
		},
		data() {
			return {
				newsInfo: {
					info: ''
				},
			}
		},
		watch: {
			$route(to,form) {
				if(to.path == form.path){
					this.getNewsInfo();
				}
			}
		},
		activated() {
			this.getNewsInfo();
		},
		deactivated() {
			this.newsInfo = {
				info: ''
			};
		},
		methods: {
			async getNewsInfo() {
				var id = this.$route.query.aid;
				var data = {
					id
				}
				this.$toast.loading('加载中...');
				const { data:res } = await this.$http.get('/article/helpDetail', { params: data });
				this.$toast.close();
				if(this.$store.state.beta){
					console.log('帮助详情：' + new Date().getHours() + ':' + new Date().getMinutes() + ':' + new Date().getSeconds() , res);
				}
				var code = res.code;
				if(code == 1) {
					var newsInfo = res.data;
					newsInfo.info.content=newsInfo.info.content.replace(/\<video/g, "<video style='width: 100%;object-fit: contain;height: 100%;margin:0 auto;'")
					newsInfo['info']['addtime'] = this.utils.js_date_time(newsInfo['info']['addtime'],1);
					this.newsInfo = newsInfo;
				} else {
					this.newsInfo = '';
				}
			}
		},
	};
</script>

<style scoped>
video{
	width: 100%!important;
	height: 100%!important;
	object-fit: contain;
	margin: 0 auto;
}	
.section1{
		margin-top: 0.3rem;
		background: #ffffff;
		box-shadow: 0 0 0.1rem rgba(0,0,0,.2);
		padding: 0.3rem;
		margin-bottom: 0.4rem;
	}
	.section1 .box{
		margin-bottom: 0.4rem;
	}
	.section1 .box .title{
		font-size: 0.4rem;
		color: #333333;
		text-align: center;
	}
	.section1 .box .info{
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin: 0.3rem 0;
		padding: 0.3rem 0;
		border-top:1px solid #E7E7E7;
		border-bottom: 1px solid #E7E7E7;
	}
	.section1 .box .info .time{
		font-size: 0.26rem;
		color: #969897;
	}
	.section1 .box .info .num{
		font-size: 0.26rem;
		color: #969897;
		padding-left: 0.3rem;
		background: url(../../assets/images/icon15.png) no-repeat left center;
		background-size: auto 0.16rem;
	}
	.section1 .singlepage{
		font-size: 0.26rem;
		color: #333333;
		line-height: 0.5rem;
		min-height: 2rem;
	}
	
	.section2{
		background: #ffffff;
		box-shadow: 0 0 0.1rem rgba(0,0,0,.2);
		margin-bottom: 0.5rem;
	}
	.section2 .item{
		padding: 0.24rem 0.3rem;
		display: flex;
		align-items: flex-start;
		border-top: 0.02rem solid #e1e1e1;
	}
	.section2 .item:first-child{
		border-top: none;
	}
	.section2 .item .text{
		font-size: 0.26rem;
		color: #333333;
	}
	.section2 .item .link{
		font-size: 0.26rem;
		color: #999999;
		max-width: 5.7rem;
	}
</style>